import { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { SecureApp } from './SecureApp'
import './App.css'

const App = () => {
    useEffect(() => {})
    return (
        <Router>
            <SecureApp />
        </Router>
    )
}

export default App
