import axios from 'axios'
import { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import { Link, useParams } from 'react-router-dom'
import * as api from '../../api'
import { RadminPage } from '../../templates/RadminPage'
import Pi from './Pi'
import { useOktaAuth } from '@okta/okta-react'
import { AuthConfig, Camera, Field, Fields, IrdPi, IrdPis, League } from '../../types'
import { parseToInt, parseToString } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { CameraControls } from './CameraControls'

import './dashboard.css'

interface Props {
    authConfig: AuthConfig
}

interface Params {
    customerName: string | undefined
}

export default function Dashboard(props: Props) {
    const { authState } = useOktaAuth()
    const [leagues, setLeagues] = useState<League[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const { customerName } = useParams<Params>()

    useEffect(() => {
        const parseCamera = (data: unknown): Camera | undefined => {
            if (typeof data !== 'object' || data === null) {
                return
            }

            const dataObj = data as { [index: string]: unknown }
            const id = parseToInt(dataObj.id)
            const name = parseToString(dataObj.name)
            const port = parseToInt(dataObj.port)

            if (id === undefined || name === undefined || port === undefined) {
                return
            }

            return { id, name, port }
        }

        const parseCameras = (data: unknown): Array<Camera> | undefined => {
            if (!Array.isArray(data)) {
                return
            }

            const cameras: Array<Camera> = []

            for (const item of data) {
                const camera = parseCamera(item)

                if (camera === undefined) {
                    continue
                }

                cameras.push(camera)
            }

            return cameras
        }

        const parsePi = (data: unknown): IrdPi | undefined => {
            if (typeof data !== 'object' || data === null) {
                return
            }

            const dataObj = data as { [index: string]: unknown }
            const id = parseToInt(dataObj.id)
            const timeZone = parseToString(dataObj.timeZone)
            const cameras = parseCameras(dataObj.cameras)

            if (id === undefined) {
                return
            }

            return { id, timeZone, cameras }
        }

        const parsePis = (data: unknown): IrdPis | undefined => {
            if (typeof data !== 'object' || data === null) {
                return
            }

            const dataObj = data as { [index: string]: unknown }
            const pis: IrdPis = {}

            for (const [keyStr, value] of Object.entries(dataObj)) {
                const key = parseToInt(keyStr)
                const pi = parsePi(value)

                if (key === undefined || pi === undefined) {
                    continue
                }

                pis[key] = pi
            }

            return pis
        }

        const parseField = (data: unknown): Field | undefined => {
            if (typeof data !== 'object' || data === null) {
                return
            }

            const dataObj = data as { [index: string]: unknown }
            const id = parseToInt(dataObj.id)
            const pis = parsePis(dataObj.pis)

            if (id === undefined) {
                return
            }

            return { id, pis }
        }

        const parseFields = (data: unknown): Fields => {
            if (typeof data !== 'object' || data === null) {
                return {}
            }

            const dataObj = data as { [index: string]: unknown }
            const fields: Fields = {}

            for (const [key, value] of Object.entries(dataObj)) {
                const name = parseToString(key)
                const field = parseField(value)

                if (name === undefined || field === undefined) {
                    continue
                }

                fields[name] = field
            }

            return fields
        }

        const parseLeague = (data: unknown): League | undefined => {
            if (typeof data !== 'object' || data === null) {
                return
            }

            const dataObj = data as { [index: string]: unknown }
            const id = parseToInt(dataObj.id)
            const name = parseToString(dataObj.name)
            const fields = parseFields(dataObj.fields)

            if (id === undefined || name === undefined) {
                return
            }

            return { id, name, fields }
        }

        const parseLeagues = (data: unknown): Array<League> => {
            if (!Array.isArray(data)) {
                return []
            }

            const leagues: Array<League> = []

            for (const item of data) {
                const league = parseLeague(item)

                if (league === undefined) {
                    continue
                }

                leagues.push(league)
            }

            return leagues
        }

        const fetchData = async () => {
            try {
                setLoading(true)

                const headers =
                    authState?.accessToken?.accessToken !== undefined
                        ? { Authorization: `Bearer ${authState?.accessToken?.accessToken}` }
                        : undefined

                const response = await axios.get(api.dashboard, {
                    params: { customer: customerName },
                    headers,
                })

                setLoading(false)
                setLeagues(parseLeagues(response.data))
            } catch (err) {
                setLeagues([])
                setTimeout(() => fetchData(), 500)
            }
        }

        fetchData()
    }, [customerName, authState])

    const renderFields = (league: League) => {
        const fields = Object.entries(league?.fields ?? {})

        if (fields.length === 0) {
            return (
                <div>
                    <br />
                    <h5 className="league-title text-center">No Fields</h5>
                </div>
            )
        } else {
            return fields.map(([name, data]) => (
                <div key={`${data.id}-div`} className="d-flex justify-content-center mb-3">
                    <Card border="secondary" text="dark" className="mb-3 text-center field-card">
                        <Card.Header as="h5" key={league.id} className="text-center">
                            <Link to={`/scheduler/${data.id}?customer=${customerName}`}>
                                Schedule {name}
                            </Link>
                        </Card.Header>
                        <Card.Body>
                            <Card.Text as="div">
                                {Object.entries(data.pis ?? {}).map(([id, data]) => (
                                    <Pi key={id} id={id} data={data} />
                                ))}
                            </Card.Text>
                        </Card.Body>
                        <Card.Subtitle className="mb-2 text-muted text-center">
                            {league.name}
                        </Card.Subtitle>
                    </Card>
                    <br />
                </div>
            ))
        }
    }

    const renderLeagues = () => {
        if (loading) {
            return (
                <div>
                    <br />
                    <h2 className="league-title text-center">Loading Leagues...</h2>
                </div>
            )
        }

        if (leagues.length === 0) {
            return (
                <div>
                    <br />
                    <h2 className="league-title text-center">No Leagues Found</h2>
                </div>
            )
        } else {
            return leagues.map((league) => (
                <div key={league.id}>
                    <h2 className="league-title text-center">
                        {league.name}
                        <br />
                        __________________________
                    </h2>
                    <br />
                    {renderFields(league)}
                </div>
            ))
        }
    }

    const renderLeagueHeaders = () => {
        return (
            <tr>
                <th>League</th>
                <th>Field</th>
                <th>Pi Prefix</th>
                <th>Camera</th>
                <th></th>
            </tr>
        )
    }

    const renderLeagueRows = (league: League) => {
        const rows: JSX.Element[] = []
        let emptyPi = 1

        for (const [name, field] of Object.entries(league.fields ?? {})) {
            const key = `${league.name}-${name}`
            const piEntries = Object.entries(field.pis ?? {})

            if (piEntries.length === 0) {
                const cols: JSX.Element[] = []

                cols.push(<td key={`${key}-league`}>{league.name}</td>)
                cols.push(
                    <td key={`${key}-field`}>
                        <span className="inline-icon">
                            <Link to={`/scheduler/${field.id}?customer=${customerName}`}>
                                <FontAwesomeIcon icon={faCalendarDays} />
                            </Link>
                        </span>
                        {name}
                    </td>
                )

                cols.push(<td key={`${key}-pi-${emptyPi}`}></td>)

                cols.push(<td key={`${key}-pi-${emptyPi}-camera`}></td>)
                cols.push(<td key={`${key}-pi-${emptyPi}-controls`}></td>)

                rows.push(<tr key={key}>{cols}</tr>)

                emptyPi += 1
                continue
            }

            for (const [prefix, pi] of piEntries) {
                for (const camera of pi.cameras ?? []) {
                    const cols: JSX.Element[] = []

                    cols.push(<td key={`${key}-league`}>{league.name}</td>)
                    cols.push(
                        <td key={`${key}-field`}>
                            <span className="inline-icon fa-icon">
                                <Link to={`/scheduler/${field.id}?customer=${customerName}`}>
                                    <FontAwesomeIcon icon={faCalendarDays} />
                                </Link>
                            </span>
                            {name}
                        </td>
                    )

                    cols.push(<td key={`${key}-pi-${prefix}`}>{prefix}</td>)

                    cols.push(
                        <td key={`${key}-pi-${prefix}-${camera.id}-camera`}>
                            {camera.port} {camera.name !== '' ? `(${camera.name})` : ''}
                        </td>
                    )

                    cols.push(
                        <td key={`${key}-pi-${prefix}-${camera.id}-controls`}>
                            <CameraControls id={camera.id} />
                        </td>
                    )
                    rows.push(<tr key={`row-${key}-pi-${prefix}-${camera.id}-controls`}>{cols}</tr>)
                }
            }
        }

        return rows
    }

    const renderLeaguesTable = () => {
        if (loading) {
            return <div />
        }

        return (
            <Table striped bordered className="dashboard-table">
                <thead>{renderLeagueHeaders()}</thead>
                <tbody>{leagues.map(renderLeagueRows)}</tbody>
            </Table>
        )
    }

    return (
        <RadminPage authConfig={props.authConfig}>
            <div>{renderLeaguesTable()}</div>
        </RadminPage>
    )
}
