import axios, { AxiosError } from 'axios'

export const errToString = (err: unknown): string => {
    if (axios.isAxiosError(err)) {
        const axiosErr = err as AxiosError
        const resp = axiosErr.response

        return resp?.status === 500 ? resp?.data : resp?.statusText
    }

    return `${err}`
}

export const parseToInt = (data: unknown): number | undefined => {
    if (typeof data === 'number') {
        return data
    } else if (typeof data === 'string') {
        const value = parseInt(data)
        return Number.isFinite(value) ? value : undefined
    } else {
        return
    }
}

export const parseToString = (data: unknown): string | undefined => {
    return typeof data === 'string' ? data : undefined
}
