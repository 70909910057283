/* ----------React & Routes---------- */
import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import * as api from '../../api'
import axios from 'axios'
/* ----------React Bootstrap---------- */
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Button from 'react-bootstrap/Button'
/* ----------Full Calendar---------- */
import dateFormat from 'dateformat'
import moment from 'moment-timezone'
import FullCalendar, {
    DateSelectArg,
    EventChangeArg,
    EventClickArg,
    EventSourceInput,
} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'

import { RadminPage } from '../../templates/RadminPage'
import { useOktaAuth } from '@okta/okta-react'
import { AuthConfig } from '../../types'

import './stream-scheduler.css' // Custom Style
import { errToString } from '../../utils'

/* To Do:
    - Month View does not display a start or end time (at least the date should be displayed)
    - Refresh Multiple times and go to Calendar, verify no wrong times (not always updating Front End?)
*/

// Time zones: https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
// Timezone offset changes in accordance with daylight savings, as applicable
// Will there be events outside of the US that we need a more complete list of time zones?
const zones = new Map([
    ['PST', 'PST8PDT'],
    ['PDT', 'PST8PDT'],
    ['MST', 'MST7MDT'],
    ['MDT', 'MST7MDT'],
    ['MTN', 'MST7MDT'],
    ['CST', 'CST6CDT'],
    ['CDT', 'CST6CDT'],
    ['EST', 'EST5EDT'],
    ['EDT', 'EST5EDT'],
])

interface Props {
    authConfig: AuthConfig
}

interface Params {
    fieldId: string | undefined
    customerName: string | undefined
}

interface FieldData {
    id: number
    name: string
    time_zone: string
    momentTZ: string
}

interface EventData {
    id: number
    start: string
    end: string
    title?: string
    description?: string
    field_id?: number
}

interface EventInfo {
    timeText: string
    event: { title: string }
}

export default function Scheduler(props: Props) {
    /* 
        Easy way to check moment-timezones against local
        Only Downside to FullCalendar is that they use npm moment...
        So we have to use it for the calendar timezone offset, or it defaults to local

        const june = moment("2014-08-20T16:00:00-05:00");
        console.log(june);
        console.log(june.tz('PST8PDT').format('ha z'));
        console.log(june.tz('MST7MDT').format('ha z'));
        console.log(june.tz('CST6CDT').format('ha z'));
        console.log(june.tz('EST5EDT').format('ha z'));
    */

    const { authState } = useOktaAuth()
    const { fieldId, customerName } = useParams<Params>()
    const [field, setField] = useState<FieldData | undefined>()

    /*----------Event Configuration----------*/
    const [isUpdate, setIsUpdate] = useState(false)
    const [scheduledEvents, setScheduledEvents] = useState<EventSourceInput>([])

    const eventDefaultState = {
        id: '',
        title: '',
        description: '',
        start: '', // yyyy-MM-ddThh:mm (no timezone) Timezone added before submission into DB
        end: '',
    }

    const [event, setEvent] = useState(eventDefaultState) // Current event being created/updated
    const [eventErrors, setEventErrors] = useState<{ [index: string]: boolean }>({})
    const [error, setError] = useState<string | undefined>()
    const [showTips, setShowTips] = useState(false)
    const [showcreateOrUpdateEvent, setShowcreateOrUpdateEvent] = useState(false)
    const [showDeleteEvent, setShowDeleteEvent] = useState(false)
    const [eventsLoaded, setEventsLoaded] = useState(false)

    const fetchEvents = useCallback(async () => {
        try {
            const resp = await axios.get(api.events, {
                params: {
                    field_id: fieldId,
                    customer: customerName,
                },
                headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` },
            })

            if (!Array.isArray(resp.data)) {
                throw new Error('Invalid events data')
            }

            const events = resp.data.map((entry) => {
                return {
                    id:
                        typeof entry.id === 'string' || typeof entry.id === 'number'
                            ? entry.id
                            : undefined,
                    start: typeof entry.start === 'string' ? entry.start : undefined,
                    end: typeof entry.end === 'string' ? entry.end : undefined,
                    title: typeof entry.title === 'string' ? entry.title : undefined,
                    description:
                        typeof entry.description === 'string' ? entry.description : undefined,
                }
            })

            setScheduledEvents(events)
            setEventsLoaded(true)
        } catch (err) {
            setEventsLoaded(false)
            setScheduledEvents([])

            handleError('Get Events', err)
        }
    }, [authState, customerName, fieldId])

    useEffect(() => {
        const fetchField = async () => {
            try {
                if (typeof fieldId !== 'string') {
                    throw new Error(`Invalid field id ${fieldId}`)
                }

                const resp = await axios.get(`${api.fields}/${fieldId}`, {
                    params: { customer: customerName },
                    headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` },
                })

                const field = resp.data

                if (typeof field !== 'object' || field === null) {
                    throw new Error(`Invalid field object`)
                }

                const fieldObj = field as { [index: string]: unknown }

                if (typeof fieldObj.timeZone !== 'string' || typeof fieldObj.name !== 'string') {
                    throw new Error(`Invalid field object`)
                }

                const zone = zones.get(fieldObj.timeZone)

                if (zone === undefined) {
                    throw new Error(`No offset zone for ${field.timeZone}`)
                }

                const idInt = parseInt(fieldId)

                if (!Number.isFinite(idInt)) {
                    throw new Error(`Invalid field id ${fieldId}`)
                }

                setField({
                    id: idInt,
                    name: fieldObj.name,
                    time_zone: fieldObj.timeZone,
                    momentTZ: zone,
                })
            } catch (err) {
                handleError('Get Field', err)
            }
        }

        setError(undefined)

        fetchField()
        fetchEvents()
    }, [fieldId, customerName, authState, fetchEvents])

    const handleError = (label: string, err: unknown) => {
        setError(`${label}: ${errToString(err)}`)
    }

    const refreshData = async () => {
        await fetchEvents()
    }

    /*----------Reset Event to Defaults----------*/
    const resetEvent = () => {
        setEvent(eventDefaultState)
    }

    /*----------Update Event Config----------*/
    const handleEventUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEvent((prevState) => ({
            ...prevState,
            [event.target.id]: event.target.value,
        }))

        if (!!eventErrors[event.target.id]) {
            setEventErrors({
                ...eventErrors,
                [event.target.id]: false,
            })
        }
    }

    const handleCloseTips = () => {
        setShowTips(false)
    }

    const handleShowTips = () => {
        setShowTips((showTips) => !showTips)
    }

    const handleCloseCreateOrUpdateEvent = () => {
        setShowcreateOrUpdateEvent(false)
        setEventErrors({})
        resetEvent()
    }

    const handleShowCreateEvent = (selected: DateSelectArg) => {
        if (typeof field?.time_zone !== 'string' || field?.time_zone === '') {
            return
        }

        setEvent((prevState) => ({
            ...prevState,
            start: selected.startStr.slice(0, selected.startStr.length - 6), // Handles different in UTC...
            end: selected.endStr.slice(0, selected.endStr.length - 6), // Handles different in UTC...
        }))

        setIsUpdate(false)
        setShowcreateOrUpdateEvent(true)
    }

    const handleShowUpdateEvent = (selected: EventClickArg) => {
        if (typeof field?.time_zone !== 'string' || field?.time_zone === '') {
            return
        }

        setEvent({
            id: selected.event.id,
            title: selected.event.title,
            description: selected.event.extendedProps.description,
            start: selected.event.startStr.slice(0, selected.event.startStr.length - 6),
            end: selected.event.endStr.slice(0, selected.event.endStr.length - 6),
        })

        setIsUpdate(true)
        setShowcreateOrUpdateEvent(true)
    }

    const handleCloseDeleteEvent = () => {
        setShowDeleteEvent(false)
        resetEvent()
    }

    const isBeforeNow = (end: string, offset?: string) => {
        if (typeof offset !== 'string') {
            return false
        }

        const endUTC = dateFormat(moment.tz(end, offset).format(), 'isoUtcDateTime')
        const nowUTC = dateFormat(new Date(Date.now()), 'isoUtcDateTime')

        return endUTC <= nowUTC
    }

    const checkEventValid = () => {
        const newErrors: { [index: string]: boolean } = {}

        if (event.end <= event.start || isBeforeNow(event.end, field?.momentTZ)) {
            newErrors.end = true
        }

        return newErrors
    }

    const dynamicallyUpdateEvent = async (selected: EventChangeArg) => {
        const data = {
            id: parseInt(selected.event.id),
            start: dateFormat(selected.event.startStr, 'isoUtcDateTime'),
            end: dateFormat(selected.event.endStr, 'isoUtcDateTime'),
        }

        await updateEvent(data)
    }

    const updateEvent = async (data: EventData) => {
        try {
            await axios.put(`${api.events}/${data.id}`, data, {
                headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` },
            })
        } catch (err) {
            handleError('Update Event', err)
        }

        await refreshData()
    }

    const createEvent = async (data: EventData) => {
        try {
            await axios.post(
                api.events,
                { ...data, status: 'future' },
                {
                    headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` },
                }
            )

            await refreshData()
        } catch (err) {
            handleError('Create Event', err)
        }
    }

    async function createOrUpdateEvent() {
        const newErrors = checkEventValid()

        if (Object.keys(newErrors).length > 0) {
            setEventErrors(newErrors)
            return
        }

        setShowcreateOrUpdateEvent(false)

        if (field === undefined) {
            return
        }

        const data = {
            id: parseInt(event.id),
            title: event.title,
            description: event.description,
            start: dateFormat(moment.tz(event.start, field.momentTZ).format(), 'isoUtcDateTime'), //new Date(`${event.start}${field.offset}`).toISOString(),
            end: dateFormat(moment.tz(event.end, field.momentTZ).format(), 'isoUtcDateTime'), //new Date(`${event.end}${field.offset}`).toISOString(),
            field_id: field.id,
        }

        isUpdate ? await updateEvent(data) : await createEvent(data)

        resetEvent()
    }

    const handleDeleteEvent = async () => {
        try {
            await axios.delete(`${api.events}/${event.id}`, {
                headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` },
            })

            await refreshData()
        } catch (err) {
            handleError('Delete Event', err)
        }
    }

    const renderDelete = () => {
        if (!isUpdate) {
            return undefined
        }

        return (
            <Button
                variant="outline-dark"
                value="Delete"
                onClick={() => {
                    setShowcreateOrUpdateEvent(false)
                    setShowDeleteEvent(true)
                }}
            >
                Delete
            </Button>
        )
    }

    const renderEventContent = (eventInfo: EventInfo) => {
        return (
            <>
                <b>{eventInfo.timeText}</b>
                <i>&nbsp;- {eventInfo.event.title}</i>
            </>
        )
    }

    const renderCalendar = () => {
        if (!eventsLoaded) {
            return <div />
        }

        return (
            <Container className="cal">
                <FullCalendar
                    //schedulerLicenseKey="XXX"
                    plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                        momentTimezonePlugin,
                    ]} //resourceTimelinePlugin
                    headerToolbar={{
                        left: 'prev,next,today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay', //timelineMonth,timelineWeek,timelineDay
                    }}
                    initialView="timeGridWeek"
                    editable={typeof field?.time_zone === 'string' && field.time_zone !== ''}
                    selectable={true}
                    selectMirror={true}
                    //themeSystem='slate'
                    dayMaxEvents={false}
                    weekends={true}
                    timeZone={field?.momentTZ} // Get from query
                    events={scheduledEvents} // alternatively, use the `events` setting to fetch from a feed initialscheduledEvents
                    eventClick={handleShowUpdateEvent} // Update/Delete Event
                    select={handleShowCreateEvent} // Create Event
                    eventContent={renderEventContent} // Display Event Information
                    eventChange={dynamicallyUpdateEvent} // Change Event Time
                    //eventsSet={} // called after scheduledEvents are initialized/added/changed/removed
                    /* 
                            you can update a remote database when these fire:
                                eventAdd={function(){}}
                                eventChange={function(){}}
                                eventRemove={function(){}}
                        */
                />
            </Container>
        )
    }

    const renderErrorText = () => {
        return (
            <div className="text-center calendar-error">
                <h1>{error}</h1>
            </div>
        )
    }

    const renderNameText = (name: string) => {
        return (
            <h2 className="text-center calendar-title">
                {eventsLoaded === true ? `Calendar: ${name}` : `Unable to load calendar events`}
            </h2>
        )
    }

    const renderTimezoneText = (tz: unknown) => {
        return (
            <h4 className="text-center calendar-title">
                {typeof tz === 'string' && tz !== ''
                    ? `Timezone: ${tz}`
                    : `Timezone unavailable... cannot edit calendar`}
            </h4>
        )
    }

    const renderTipsButton = () => {
        return (
            <div className="text-center">
                <Button variant="outline-dark" value="showTips" onClick={handleShowTips}>
                    Calendar Tips
                </Button>
            </div>
        )
    }

    const renderTipsModal = () => {
        return (
            <Offcanvas
                placement="end"
                show={showTips}
                onHide={handleCloseTips}
                scroll={true}
                backdrop={false}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Calendar Instructions</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ul>
                        <li>Select dates and you will be prompted to create a new event</li>
                        <li>Drag, drop, and resize events</li>
                        <li>Click an event to update or delete it</li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        )
    }

    const renderCreateModal = () => {
        return (
            <Modal
                show={showcreateOrUpdateEvent}
                onHide={() => {
                    handleCloseCreateOrUpdateEvent()
                }}
            >
                <Form>
                    <Modal.Header closeButton>
                        <Modal.Title>{isUpdate ? 'Update' : 'Create'} Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Form.Group as={Col} className="mb-2">
                                <Form.Label>Title:</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="title"
                                    value={event.title}
                                    onChange={handleEventUpdate}
                                    placeholder=""
                                    //required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide an event title.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} className="mb-2">
                                <Form.Label>Description:</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="description"
                                    value={event.description ?? ''}
                                    onChange={handleEventUpdate}
                                    placeholder=""
                                    as="textarea"
                                    rows={3}
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} className="mb-2">
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    id="start"
                                    value={event.start}
                                    onChange={handleEventUpdate}
                                    required
                                />
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Col} className="mb-2">
                                <Form.Label>End Time</Form.Label>
                                <Form.Control
                                    type="datetime-local"
                                    id="end"
                                    value={event.end}
                                    onChange={handleEventUpdate}
                                    isInvalid={!!eventErrors.end}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    The event must end after the start time and the current time.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {renderDelete()}

                        <Button variant="outline-dark" onClick={createOrUpdateEvent}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        )
    }

    const renderConfirmDeleteModal = () => {
        return (
            <Modal show={showDeleteEvent} onHide={handleCloseDeleteEvent}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Delete Event: {showDeleteEvent === true ? event.title : ''}?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this event?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-dark"
                        value="Delete"
                        onClick={() => {
                            handleDeleteEvent()
                            handleCloseDeleteEvent()
                        }}
                    >
                        Delete
                    </Button>
                    <Button variant="outline-dark" type="button" onClick={handleCloseDeleteEvent}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <RadminPage authConfig={props.authConfig}>
            {renderErrorText()}
            {renderNameText(field?.name ?? '')}
            {renderTimezoneText(field?.time_zone)}
            {renderCalendar()}

            <br />

            {renderTipsButton()}
            {renderTipsModal()}

            {renderCreateModal()}
            {renderConfirmDeleteModal()}
        </RadminPage>
    )
}
