const OKTA_DOMAIN = 'dev-9743287.okta.com'
const CALLBACK_PATH = '/login/callback'

const ISSUER = `https://${OKTA_DOMAIN}/oauth2/default`
const HOST = window.location.host
const PROTOCOL = window.location.protocol
const REDIRECT_URI = `${PROTOCOL}//${HOST}${CALLBACK_PATH}`
const SCOPES = 'openid profile email'

export const config = {
    issuer: ISSUER,
    clientId: '0oa18j2dfu4kmgnhs5d7',
    redirectUri: REDIRECT_URI,
    scopes: SCOPES.split(/\s+/),
    pkce: true,
    disableHttpsCheck: 'false',
}
