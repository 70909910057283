import axios from 'axios'
import * as api from '../../api'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate, faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { errToString } from '../../utils'
import { useOktaAuth } from '@okta/okta-react'

interface Props {
    id: number
}

export const CameraControls = (props: Props) => {
    const [errMsg, setErrMsg] = useState<string | undefined>(undefined)
    const { authState } = useOktaAuth()

    const withAction = async (fn: () => Promise<void>) => {
        try {
            setErrMsg(undefined)
            await fn()
        } catch (err) {
            setErrMsg(errToString(err))
        }
    }

    const hitStart = () => {
        withAction(() =>
            axios.put(
                `${api.cameras}/${props.id}`,
                { action: 'start' },
                {
                    headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` },
                }
            )
        )
    }

    const hitStop = () => {
        withAction(() =>
            axios.put(
                `${api.cameras}/${props.id}`,
                { action: 'stop' },
                {
                    headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` },
                }
            )
        )
    }

    const hitReset = () => {
        withAction(() =>
            axios.put(
                `${api.cameras}/${props.id}`,
                { action: 'reset' },
                {
                    headers: { Authorization: `Bearer ${authState?.accessToken?.accessToken}` },
                }
            )
        )
    }

    const renderErrorMsg = () => {
        if (typeof errMsg !== 'string') {
            return ''
        }

        return <span className="error-message">{errMsg}</span>
    }

    return (
        <div>
            <Button onClick={() => hitStart()} variant="outline-light">
                <FontAwesomeIcon className="fa-icon" icon={faPlay} />
            </Button>
            <Button onClick={() => hitStop()} variant="outline-light">
                <FontAwesomeIcon className="fa-icon" icon={faStop} />
            </Button>
            <Button onClick={() => hitReset()} variant="outline-light">
                <FontAwesomeIcon className="fa-icon" icon={faArrowsRotate} />
            </Button>

            {renderErrorMsg()}
        </div>
    )
}
