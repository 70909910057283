export const dashboard = '/api/dashboard'
export const users = '/api/users'
export const pis = '/api/pis'
export const cameras = '/api/cameras'
export const leagues = '/api/leagues'
export const events = '/api/events'
export const fields = '/api/fields'
export const reset = '/api/camera-actions/reset'
export const start = '/api/camera-actions/start'
export const stop = '/api/camera-actions/stop'
export const status = '/api/dashboard/pis'
