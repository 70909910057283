import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react'
import { Route, useHistory, Redirect } from 'react-router-dom'
import { config } from './okta-config'
import Dashboard from './pages/Dashboard'
import StreamScheduler from './pages/StreamScheduler'

export const SecureApp = () => {
    const history = useHistory()
    const oktaAuth = new OktaAuth(config)

    const restoreOriginalUri = async (oktaAuth: unknown, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin))
    }

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Route exact path="/">
                <Redirect to="/gds" />
            </Route>
            <SecureRoute
                exact
                path="/:customerName"
                component={() => <Dashboard authConfig={config} />}
            />
            <SecureRoute
                exact
                path={`/scheduler/:fieldId`}
                component={() => <StreamScheduler authConfig={config} />}
            />
            <Route exact path={`/login/callback`} component={LoginCallback} />
        </Security>
    )
}
