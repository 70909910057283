import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { useOktaAuth } from '@okta/okta-react'
import { AuthConfig } from '../types'

import './RadminPage.css'

interface Props {
    authConfig: AuthConfig
    children: JSX.Element | JSX.Element[]
}

export const RadminPage = (props: Props) => {
    const { authState, oktaAuth } = useOktaAuth()

    const renderAuthButton = () => {
        if (authState === null) {
            return
        }

        if (authState.isAuthenticated) {
            return (
                <button className="login-button" onClick={() => oktaAuth.signOut()}>
                    Logout
                </button>
            )
        }
    }

    const renderNavBar = () => {
        return (
            <Navbar className="navbar-override" variant="dark" bg="dark">
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        width="30"
                        height="30"
                        src="/logo.png"
                        className="d-inline-block align-top"
                    />
                    Gigcasters
                </Navbar.Brand>

                <Nav className="ms-auto">{renderAuthButton()}</Nav>
            </Navbar>
        )
    }

    return (
        <>
            <div className="radmin-navbar">{renderNavBar()}</div>
            <div className="radmin-content">{props.children}</div>
        </>
    )
}
